/*
 * @Author: mulingyuer
 * @Date: 2021-04-23 11:35:25
 * @LastEditTime: 2022-04-20 15:42:31
 * @LastEditors: aleaner
 * @Description:上传文件
 * @FilePath: \base\utils\upload4.js
 * 怎么可能会有bug！！！
 */

import md5 from 'js-md5'
import api from './request'
import { timestamp, randomString, getType } from '@/base/utils/tool'
import { Message, MessageBox } from 'element-ui'
import AQM from './async-queue-manager' //异步队列管理
//异步队列管理配置：maxParallel线程数
const AQMConfig = { maxParallel: 3 }

const MB = 1024 * 1024

const errorMsg = {
  notExist: '没有检测到上传的内容！',
  config: '上传配置出错，config或者configApi参数不正确！',
  max(size) {
    return `文件超出了最大限制，最大为${size / MB}MB`
  },
  notUrl: '上传地址不能为空',
}

class Upload {
  // 一次性抓取所有腾讯云存储参数，备用
  AuthData = null
  TaskId = ''

  //上传配置：config={} configApi="",//上传配置api
  constructor(options = {}) {
    return this.run(options)
  }

  async run(options) {
    await this.initOptions(options)

    return new Promise(async (resolve, reject) => {
      try {
        const data = await this.uploadFile()
        resolve(data)
      } catch (err) {
        reject(err)
      }
    })
  }

  static getFileName(file) {
    return file.name
  }

  //挂载基本数据
  async initOptions(options) {
    const baseOption = {
      maxSize: 2 * MB, // 默认切片大小
      allowSlice: 0, // 是否允许切片
      url: '', // 上传文件的api
      //头信息
      headers: {
        'Content-Type': 'multipart/form-data',
        timestamp: timestamp(),
        nonce: randomString(16),
      },
      typeArr: ['image', 'audio', 'video'], // 文件类型
      method: 'post', //上传协议
      data: [], //上传的文件数据，单文件、多文件类似数组、多文件数组
      pid: 0, // 要上传至媒体库的哪个分组（即文件夹，0表示根目录）
      timeout: 25000,
      allowCos: true /* 允许使用直传 */,
      encrypt: 0,
    }
    this.options = Object.assign({}, baseOption, options)
    // console.log('final options of files upload', this.options)
    //文件转数组
    if (typeof this.options.data[Symbol.iterator] === 'function') {
      this.options.data = [...this.options.data]
    } else if (getType(this.options.data) === 'file') {
      this.options.data = [this.options.data]
    }
    //无文件报错
    if (!this.options.data.length) {
      Message.error(errorMsg.notExist)
      throw new Error(errorMsg.notExist)
    }
    //上传进度回调
    this.options.progress = this.options.progress ?? function () {}

    //上传配置
    let upConfig = {}
    if (options.configApi === undefined && !options.url) {
      // 未传入configApi字段 又未传入url字段
      throw new Error('获取上传配置出错！' + error)
    } else if (getType(options.configApi) === 'string') {
      try {
        const { data } = await api({
          url: options.configApi,
          method: 'post',
          notCancel: true,
        })
        upConfig = data
      } catch (error) {
        throw new Error('获取上传配置出错！' + error)
      }
    } else if (getType(options.config) === 'object') {
      upConfig = options.config
    }
    this.options.allowSlice =
      upConfig['support_slice_upload'] ?? this.options.allowSlice
    // 没有配置url才使用api获取的上传url地址
    if (!this.options.url) {
      const upUrl = upConfig['url']
      if (upUrl) {
        if (/^http/.test(upUrl)) {
          this.options.url = ''
          this.options.baseUrl = upUrl
        } else {
          this.options.url = upUrl
        }
      }
    } else {
      this.options.allowCos = false
    }
    //上传配置要求的头信息
    if (upConfig['upload_token']) {
      this.options.headers['upload_token'] = upConfig['upload_token']
    }
    //上传配置要求的maxSize
    this.options.maxSize = upConfig['max_size'] ?? this.options.maxSize

    // if (typeof this.options.encrypt === 'boolean') {
    //   this.options.encrypt = this.options.encrypt ? 1 : 0
    // }
    this.options.encrypt = 0 // 一键关闭
  }

  // 获取签名等信息备用
  getAuthorization(file) {
    return new Promise((resolve, reject) => {
      api({
        url: '/admin/admin/media/uploadCosTemporaryAuth',
        data: {
          type: this.getFileType(file) || 'image',
          file_name: Upload.getFileName(file), // 适配可能没有文件名字段
          encrypt: this.options.encrypt
        },
        method: 'post',
      })
        .then(({ data }) => {
          if (data) {
            this.AuthData = data
            /**
               * {
            "bucket_id":"",
            "region":"",
            "tmp_id":"",
            "tmp_key":"",
            "tmp_token":"",
            "start_time":"",
            "expire_time":"",
            "scope_limit":true,
            "expire_in": 1800,
            "title": "123.jpg",
            "dir": "cos/q64KaQle/images",
            "file_ext": "jpg",
            "key": "cos/q64KaQle/images/20240517/e7d6adb8906b1006d3dd1e68cc5537cd.jpg",
            "protocol":"",
            "domain":""
        }
               */
          } else {
            MessageBox.alert(JSON.stringify(data), {
              title: '临时密钥获取失败',
              showCancelButton: false,
            }).catch(() => {})
          }

          resolve(data)
        })
        .catch((data) => {
          console.log(data)
          MessageBox.alert(JSON.stringify(data), {
            title: '临时密钥获取失败',
            showCancelButton: false,
          }).catch(() => {})
          reject(data)
        })
    })
  }

  // 将 cos 文件保存关联到媒体库
  saveCosFile(file, data) {
    const AuthData = this.AuthData
    return api({
      url: '/admin/admin/media/saveCosUploadFile',
      data: {
        // 文件自身的属性
        type: this.getFileType(file) || 'image',
        file_size: file.size,

        // 后端返回的
        title: AuthData?.title || Upload.getFileName(file),
        dir: AuthData?.dir,
        file_ext: AuthData?.file_ext,

        // 直传返回的
        location: data.Location,
        etag: data.ETag,

        // 其他
        extra: this.options.pid
            ? JSON.stringify({ pid: this.options.pid })
            : JSON.stringify(this.options.extra || {}),

        // 临时访问
        encrypt: this.options.encrypt
      },
      method: 'post',
    })
  }

  /**
   * 获得 cos 对象来操作高级上传
   */
  async initCos(file) {
    let AuthData = await this.getAuthorization(file)

    if (!AuthData) return null

    // console.log(COS.version);  sdk 版本需要不低于 1.7.2
    return new COS({
      SecretId: AuthData.tmp_id, // sts服务下发的临时 secretId
      SecretKey: AuthData.tmp_key, // sts服务下发的临时 secretKey
      SecurityToken: AuthData.tmp_token, // sts服务下发的临时 SessionToken
      StartTime: AuthData.start_time, // 建议传入服务端时间，可避免客户端时间不准导致的签名错误
      ExpiredTime: AuthData.expire_time, // 临时密钥过期时间
      SimpleUploadMethod: 'putObject', // 强烈建议，高级上传、批量上传内部对小文件做简单上传时使用putObject,sdk版本至少需要v1.3.0
      Domain: AuthData.domain,
    })
  }

  /**
   * entry
   * 腾讯云 COS 直传
   * 上传文件入口
   */
  async cosUpload(file) {
    let that = this
    // 1、new一个对象
    const cos = await this.initCos(file)
    const AuthData = this.AuthData

    if (!cos) {
      return Promise.reject(null)
    }

    // 2、调用该对象的上传方法
    const result = await cos.uploadFile(
      {
        Bucket: AuthData?.bucket_id /* 填写自己的 bucket，必须字段 */,
        Region: AuthData?.region /* 存储桶所在地域，必须字段 */,
        Key: AuthData?.key /* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */,
        Body: file /* 上传的文件对象，必须字段 */,
        SliceSize:
          5 *
          MB /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */,
        onTaskReady: function (taskId) {
          that.TaskId = taskId // 记录 TaskId，有需要取消上传任务时有用
        },
        // 上传进度监听，现在没啥用
        onProgress: function (info) {
          var percent = parseInt(info.percent * 10000 + '') / 100
          var speed = parseInt((info.speed / MB) * 100 + '') / 100
          console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;')
          if (that.options.data.length === 1) that.options.progress(percent, {
            file, percent, speed
          })
        },
        onFileFinish: function (err, data, options) {
          console.log(options.Key + '上传' + (err ? '失败' : '完成'))
        },
      }
      // function (err, data) {
      //   if (err) {
      //     console.log('上传失败', err)
      //   } else {
      //     // 看看 data 数据格式
      //     /**
      //      * UploadId: "1722391542bf6919e25a8f088dd1f6796b573e4bc7e49a8ab3bd3c0141a0b38d20d7ed232c",
      //      * Location: "res.reaidao.cn/cos/testdata/admin/videos/20240731/bda4438202116e948c0dbd616c62d13d.mp4",
      //      * Bucket: "reaidao-1303247975",
      //      * Key: "cos/testdata/admin/videos/20240731/bda4438202116e948c0dbd616c62d13d.mp4",
      //      * ETag: ""0092a03e7ba4d346152e1c438aee5b94-88""
      //      * RequestId: "NjZhOTljMTBfOTA4ZTIwMDlfY2NkOV8yMThkYjA3"
      //      * headers: {content-type: "application/xml", transfer-encoding: "chunked", connection: "keep-alive", date: "Wed, 31 Jul 2024 02:06:08 GMT", server: "tencent-cos", …}
      //      * statusCode: 200
      //      */
      //     console.log('上传成功', data)
      //   }
      // } /* callback 和 promise 方式二选一 */
    )

    // return cos // 可通过 await 得到，用于操作其他事务
    return that.saveCosFile(file, result)

    return new Promise((resolve, reject) => {
      /**
       * 绑定到媒体库链接，
       * 返回格式如：{type, url, duration, thumbnail}
       * 图片的 duration、thumbnail 都是 null
       */
      that
        .saveCosFile(file, result)
        .then(({data}) => {
          /**
             * 数据示例：
             *         "type": 3,
             "pid": 0,
             "description": null,
             "title": "Free_Test_Data_2MB_MP4.mp4",
             "config": {
            "thumbnail": "https:\/\/testsaasres.shetuan.pro\/https%3A\/testsaasres.shetuan.pro\/cos\/qXV4g30AvmQRawYK\/videos\/20240520\/86c13271f8eb92b263ef9c7fd8084ada.jpg",
            "duration": 55
        },
             "create_time": 1716176882,
             "file_size": 2100335,
             "url": "https:\/\/testsaasres.shetuan.pro\/cos\/qXV4g30AvmQRawYK\/videos\/20240520\/86c13271f8eb92b263ef9c7fd8084ada.mp4",
             "etag": null,
             "res_server": "tencent-cos",
             "id": "10968"
             */
          console.log(data)
          resolve({
            data: data,
          }) /* 整体保持一致：{code, data, msg} */
        })
        .catch((err) => {
          console.log(err, 'eeeeeeeeeeeee')
          reject(err)
        })
    })
  }

  //上传方法
  async uploadFile() {
    const maxFile = this.options.data.find((file) => {
      console.log('file size: ', file.size / MB, 'MB')

      return file.size > this.options.maxSize
    })
    if (maxFile && this.options.allowSlice === 0 && !this.options.allowCos) {
      const msg = errorMsg.max(this.options.maxSize)
      Message.error(msg)
      throw new Error(msg)
    }
    //单文件上传
    const { data, allowCos } = this.options
    if (data.length === 1) {
      if (allowCos) {
        // 不用管文件大小
        return this.cosUpload(data[0])
      } else if (data[0].size <= this.options.maxSize) {
        return this.smallFileUpload(data[0], true)
      } else {
        let f = data[0]
        if (f.type.indexOf('image') !== -1) {
          f = await this.dealWidthLargeImage(f)
        }
        if (f.size > this.options.maxSize) {
          return this.bigFileUpload(f, true)
        } else {
          return this.smallFileUpload(f, true)
        }
      }
    } else {
      //多文件上传
      return this.multiFileUpload()
    }
  }

  // 小文件上传
  smallFileUpload(file, isSingleFile = false) {
    const postData = this.formatFormData({
      type: this.getFileType(file),
      file,
      file_name: file.name,
      is_slice_upload: 0,
      upload_md5: this.md5Key(file),
      slice_index: 0,
      is_last_slice: 1,
      last_hash: '', // 整个文件的 md5 值 小文件不用md5
      extra: this.options.pid
        ? JSON.stringify({ pid: this.options.pid })
        : JSON.stringify(this.options.extra || {}),
      encrypt: this.options.encrypt
    })
    return this.myAxios(postData, isSingleFile)
  }

  // 大文件上传 file：文件对象，isSingleBigFile：单个大文件才触发进度条回调
  bigFileUpload(file, isSingleBigFile = false) {
    return new Promise(async (resolve, reject) => {
      const type = this.getFileType(file)

      if (!type) {
        const msg = '不支持该类型的文件'
        this._errorToast(msg)
        reject({
          msg,
        })
        return
      }

      try {
        // 遵循第一片第一传，最后一片最后传
        let sliceArr = await this.fileSlice(file)
        const sliceLength = sliceArr.length //总片数
        let completeLength = 0 //已完成片数
        const startSlice = sliceArr[0]
        const endSlice = sliceArr[sliceArr.length - 1]

        //进度条
        const bigProgress = (length) => {
          let complete = ((length / sliceLength) * 100) | 0
          this.options.progress(complete)
        }

        //先传第一片
        await this.myAxios(this.formatFormData(startSlice))
        if (isSingleBigFile) bigProgress((completeLength += 1))

        //除去首尾各一片-切片上传
        if (sliceArr.length > 2) {
          sliceArr = sliceArr.slice(1, sliceArr.length - 1)
          if (isSingleBigFile) {
            // 单个大文件上传需要实时返回上传进度
            await this.sliceArrUpload(sliceArr, () => {
              bigProgress((completeLength += 1))
            })
          } else {
            await this.sliceArrUpload(sliceArr)
          }
        }

        //最后一片
        const res = await this.myAxios(this.formatFormData(endSlice))
        if (isSingleBigFile) bigProgress((completeLength += 1))
        return resolve(res)
      } catch (err) {
        return reject(err)
      }
    })
  }

  //切片数组上传
  sliceArrUpload(sliceArr, callback = () => {}) {
    return new Promise((resolve, reject) => {
      const aqm = new AQM(AQMConfig)

      sliceArr.forEach((slice) => {
        //创建任务函数
        let asyncTask = () => {
          return this.myAxios(this.formatFormData(slice))
        }
        aqm.push(asyncTask)
      })

      aqm
        .start(callback)
        .then(() => {
          return resolve()
        })
        .catch((err) => {
          return reject(err)
        })
    })
  }

  //获取文件类型
  getFileType(file) {
    const fileType = file.type.split('/')[0]
    let type = 'file'
    if (fileType && this.options.typeArr.includes(fileType)) {
      type = fileType
    }
    return type
  }

  //多文件上传
  multiFileUpload() {
    return new Promise(async (resolve, reject) => {
      try {
        const fileLength = this.options.data.length
        let index = 0
        let successData = []
        while (index < fileLength) {
          const file = this.options.data[index++]
          if (this.options.allowCos) {
            const { data } = await this.cosUpload(file)
            successData.push(data)
          } else if (file.size <= this.options.maxSize) {
            const { data } = await this.smallFileUpload(file)
            successData.push(data)
          } else {
            let f = file
            if (f.type.indexOf('image') !== -1) {
              f = await this.dealWidthLargeImage(f)
            }
            if (f.size > this.options.maxSize) {
              const { data } = await this.bigFileUpload(f)
              successData.push(data)
            } else {
              const { data } = await this.smallFileUpload(f)
              successData.push(data)
            }
          }
          //进度条
          let complete = ((index / fileLength) * 100) | 0
          this.options.progress(complete)
        }
        return resolve(successData)
      } catch (err) {
        return reject(err)
      }
    })
  }

  //唯一key（可由 {文件名 + 时间戳 + 随机字符串} md5 生成）
  md5Key(file) {
    return md5(`${file.name}${new Date().valueOf()}${randomString()}`)
  }

  //转formData
  formatFormData(config) {
    let fd = new FormData()
    //遍历
    Object.keys(config).forEach((key) => {
      if (key === 'file') {
        fd.append(key, config[key], config.file_name)
      } else {
        fd.append(key, config[key])
      }
    })
    if (config.extra === undefined) fd.append('extra', '{}') //额外参数，json
    return fd
  }

  //上传方法 data:文件对象，isSingleFile：是否为单文件上传（进度计算方式不同，需区分）
  myAxios(data, isSingleFile = false) {
    let postData = {
      url: this.options.url,
      method: this.options.method,
      headers: this.options.headers,
      notCancel: true, //不允许取消
      data,
      timeout: this.options.timeout,
    }
    if (this.options.baseUrl) {
      postData.baseUrl = this.options.baseUrl
      postData.url = ''
    }
    if (isSingleFile) {
      postData.onUploadProgress = (progressEvent) => {
        //当前文件进度
        let complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0
        this.options.progress(complete)
      }
    }
    return api(postData)
  }

  //文件切片
  async fileSlice(file) {
    let startSize = 0 //起始切片大小
    let index = 0 //切片索引
    let last_hash = md5.create() //md5
    let sliceArr = [] //切片容器数组
    //基础数据--素体
    let sdsee = {
      type: this.getFileType(file),
      file_name: file.name,
      is_slice_upload: 1, //1大文件上传
      upload_md5: this.md5Key(file.name),
      encrypt: this.options.encrypt
    }

    //切片
    while (startSize < file.size) {
      //切片结束点
      let endSize = startSize + this.options.maxSize
      const endSlice = endSize >= file.size
      if (endSlice) {
        endSize = file.size
      }

      //获得切片和md5
      const blob = file.slice(startSize, endSize) //切片
      const buff = await this.changeBuff(blob) //buff
      last_hash.update(buff) //更新md5

      //创建上传的文件对象
      const data = Object.assign({}, sdsee, {
        file: blob,
        slice_index: index,
        is_last_slice: endSlice ? 1 : 0, //是否为最后一片
        last_hash: endSlice ? last_hash.hex() : '',
        extra: this.options.pid
          ? JSON.stringify({ pid: this.options.pid })
          : JSON.stringify(this.options.extra),
      })

      //传入切片容器
      sliceArr.push(data)
      index++
      startSize = endSize //下次起始大小
    }

    return sliceArr
  }

  // blob转buff
  changeBuff(blob) {
    const reader = new FileReader()
    reader.readAsArrayBuffer(blob)
    return new Promise((resolve, reject) => {
      reader.onload = function (event) {
        resolve(reader.result)
      }
    })
  }

  /**
   * 对大图进行提前处理
   */
  async dealWidthLargeImage(file) {
    // 图片大于5M压缩
    if (file.size > 1024 * 1024 * 5) {
      // TODO: 开始压缩，添加友好提示，通知用户等待
      console.log(
        'compress before: ',
        file.size / 1024 / 1024 + 'MB',
        file.type,
        file.name
      )
      let file_name = file.name.split('.')[0] + '.jpeg'
      let f = await this.compressImage(file)
      file = this.convertBase64UrlToBlob(f)
      file.name = file_name
      // file.type = "image/jpeg"
      console.log(
        'compress after: ',
        file.size / 1024 / 1024 + 'MB',
        file.type,
        file.name
      )
      // TODO: 压缩结束提示
    } else {
      // console.log('图片小于5M，不处理')
    }
    return file
  }

  /**
   * 利用canvas压缩图片，得到base64
   * @param file
   * @returns {Promise<unknown>}
   */
  compressImage(file) {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    return new Promise((resolve, reject) => {
      reader.onload = function (e) {
        let img = new Image()
        // base64
        img.src = this.result
        img.onload = function () {
          let originWidth = img.width
          let originHeight = img.height

          /**
           * 1、先计算 L=最长边，R=长边比短边
           * 2、若 L < 2400 不处理
           * 3、R<2，最长边压为 2400
           * 4、R>2，若 L < 4800，最长边压为 2400，否则最长边压为 4800
           */
          if (originWidth < 2400 && originHeight < 2400) resolve(img.src)
          // 竖图，宽度小于高度
          let isVertical = originWidth < originHeight
          // 长边
          let long = isVertical ? originHeight : originWidth
          // 短边
          let short = isVertical ? originWidth : originHeight
          // 长短比
          let ratio = long / short
          // 目标长边
          let distLong
          if (ratio <= 2 || long < 4800) {
            distLong = 2400
          } else {
            distLong = 4800
          }

          let canvas = document.createElement('canvas')
          let context = canvas.getContext('2d')
          canvas.width = isVertical ? distLong / ratio : distLong // 压缩后的宽度
          canvas.height = (originHeight * canvas.width) / originWidth
          context.drawImage(img, 0, 0, canvas.width, canvas.height)

          /**
           * 只有jpeg支持quality设置
           * quality值越小，所绘制出的图像越模糊
           */
          let quality = 1
          resolve(canvas.toDataURL('image/jpeg', quality))
        }
      }
    })
  }

  /**
   * 将canvas导出的base64转为blob
   * @param urlData
   * @returns {Blob}
   */
  convertBase64UrlToBlob(urlData) {
    var arr = urlData.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: 'image/jpeg' })
  }

  /**
   * 错误提示，根据字数使用不同的api
   * @param msg
   * @private
   */
  _errorToast(msg) {
    msg =
      {
        'request:fail timeout': '上传超时',
      }[msg] ||
      msg ||
      '上传失败'

    const msgLength = msg.length
    if (msgLength)
      if (msgLength >= 24) {
        MessageBox.alert(msg, {
          showCancelButton: false,
        }).catch(() => {})
      } else {
        this.$message.error(msg)
      }
  }
}

function run(options) {
  return new Upload(options)
}

export default run
